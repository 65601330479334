alert-box {
  display: none;
}

.alert-box {
  border: 1px solid #ccc;
  color: #454545;
  padding: 8px;
  position: relative;

  a {
    color: inherit;
    text-decoration: underline;
  }

  @at-root {
    &__info {
      background-color: rgba(38, 111, 173, 6%);
      border-color: rgba(38, 111, 173, 40%);
      color: rgba(38, 111, 173, 90%);
    }

    &__attention {
      background-color: $white;
      border: 1px solid #e5ddc7;
      display: flex;
      gap: 6px;
      margin-bottom: 20px;
      padding: 12px;

      &::before {
        color: $red;
        content: $icon-exclamation-circle;
        font-family: $font-icon;
        font-size: 16px;
        padding-top: 2px;
      }
    }

    &__danger {
      background-color: #fdf4f3;
      border-color: rgba(173, 0, 8, 30%);
      color: $dark-red;
    }

    &__closable {
      padding-right: 28px;

      .close-button {
        color: rgba(38, 111, 173, 40%);
        height: 14px;
        line-height: 14px;
        margin: 4px;
        padding: 6px;
        position: absolute;
        right: 0;
        text-align: center;
        text-decoration: none;
        top: 0;

        &::before {
          content: $icon-cross1;
          display: inline-block;
          font-family: $font-icon;
          height: 14px;
          line-height: 14px;
          width: 14px;
        }

        &:hover {
          color: rgba(38, 111, 173, 80%);
        }
      }
    }
  }
}
